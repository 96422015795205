/* You can add global styles to this file, and also import other style files */
@import url(assets/fonts/persian/fontiran.css);

body {
  font-family: -apple-system,BlinkMacSystemFont,IRANSans,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: -apple-system,BlinkMacSystemFont,IRANSans,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  font-weight: 300;
  line-height: 1.15em;
}

.app-sidebar, .off-canvas-sidebar {
  font-family: -apple-system,BlinkMacSystemFont,IRANSans,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
