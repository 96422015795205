// Theme Colors
$theme-colors: (primary: #009DA0,
success: #0CC27E,
info: #1CBCD8,
warning: #FF8D60,
danger: #FF586B,
secondary: #868e96,
light: #f8f9fa,
dark: #343a40);

// Spacing

$spacer: 1.5rem;

$spacers: (0: 0,
1: ($spacer * .25),
2: ($spacer * .50),
3: $spacer,
4: ($spacer * 1.5),
5: ($spacer * 3)) !default;

// Body
$body-bg: #f5f7fa;

// Fonts
$font-family-sans-serif: "Montserrat",
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif;
$font-family-content: "Rubik",
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif;

$card-spacer-y : 1.5rem;
$card-spacer-x : 1.5rem;

$font-size-root: 15px;
