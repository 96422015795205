@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('fonts/iransansweb_bold.woff') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('fonts/IRANSansWeb_Medium.woff') format('woff');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('fonts/iransansweb_light.woff') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/iransansweb.woff') format('woff'); /* All Major Browsers */
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('fonts/iransansweb_ultralight.woff') format('woff');
}
